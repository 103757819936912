import bg0_1 from './photos/bg0_1.js'
import bg0_2 from './photos/bg0_2.js'
import bg0_3 from './photos/bg0_3.js'
import bg0_4 from './photos/bg0_4.js'
import bg2_home_1 from './photos/bg2_home_1.js'
import bg2_home_2 from './photos/bg2_home_2.js'
import bg2_home_3 from './photos/bg2_home_3.js'
import bg2_home_4 from './photos/bg2_home_4.js'
import bg3_work_1 from './photos/bg3_work_1.js'
import bg3_work_2 from './photos/bg3_work_2.js'
import bg3_work_3 from './photos/bg3_work_3.js'
import bg3_work_4 from './photos/bg3_work_4.js'
import bg4_other_1 from './photos/bg4_other_1.js'
import bg4_other_2 from './photos/bg4_other_2.js'
import bg4_other_3 from './photos/bg4_other_3.js'
import bg5_outside_1 from './photos/bg5_outside_1.js'
import bg5_outside_2 from './photos/bg5_outside_2.js'
import bg5_outside_3 from './photos/bg5_outside_3.js'
import bg6_school_1 from './photos/bg6_school_1.js'
import bg6_school_2 from './photos/bg6_school_2.js'
import bg6_school_3 from './photos/bg6_school_3.js'
export default [
    bg0_1,
    bg0_2,
    bg0_3,
    bg0_4,
    bg2_home_1,
    bg2_home_2,
    bg2_home_3,
    bg2_home_4,
    bg3_work_1,
    bg3_work_2,
    bg3_work_3,
    bg3_work_4,
    bg4_other_1,
    bg4_other_2,
    bg4_other_3,
    bg5_outside_1,
    bg5_outside_2,
    bg5_outside_3,
    bg6_school_1,
    bg6_school_2,
    bg6_school_3
]