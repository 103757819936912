import assign from 'assign-deep'
import baseStyle from '@/store/base/v2/style'
import baseOption from '@/store/base/v2/option'

import option from './option'

export default {
  setting: {
    appId: 'default',
    appName: 'バーチャル名刺背景ジェネレーター',
    hideDeveloper: false,
    hideProduceBy: false,
    hideVersion: false,
    homePath: '/',
    gaPath: '/virtualmeishihaikei-generator',
    routes: {
      generator: true,
      howtouse: true,
      feedback: true,
      custom: true,
    },
  },
  edit: {
    backgroundImage: option.backgroundImage[0],
    corpLogo: option.corpLogo[0],
    department: '部署名',
    title: '役職名',
    nameMain: '日向ナナ',
    nameSub: 'Nana Hinata',
    mail: 'YourMail@sample.com',
    eight: 'https://8card.net/virtual_cards/',
    sns: [
      {
        name: 'web',
        content: 'https://kidsplates.jp/',
      },
      {
        name: 'facebook',
        content: 'YourFacebookPageId',
      },
      {
        name: 'twitter',
        content: 'YourTwitterId',
      },
    ]
  },
  style: assign(baseStyle, {}),
  option: assign(baseOption, option)
}
