<template>
<nav class="navbar navbar-expand-lg border-bottom">
  <router-link class="navbar-brand" :to="{name: 'home'}">{{ setting.appName }}</router-link>
  <div class="navbar-nav mr-2" v-if="!setting.hideVersion"><small>v0.4.0</small></div>
  <button class="navbar-toggler" data-target="#my-nav" data-toggle="collapse" aria-controls="my-nav" aria-expanded="false" aria-label="Toggle navigation">
    <span class="navbar-toggler-icon"></span>
  </button>
  <div id="my-nav" class="collapse navbar-collapse">
    <ul class="navbar-nav mr-auto">
      <li class="nav-item" v-if="setting.routes.generator">
        <router-link class="nav-link" :to="{name: 'generator'}">ジェネレーター</router-link>
      </li>
      <li class="nav-item" v-if="setting.routes.howtouse">
        <router-link class="nav-link" :to="{name: 'howtouse'}">使い方</router-link>
      </li>
      <!-- <li class="nav-item" v-if="setting.routes.feedback">
        <router-link class="nav-link" :to="{name: 'feedback'}">ご意見</router-link>
      </li> -->
      <li class="nav-item" v-if="setting.routes.custom">
        <router-link class="nav-link" :to="{name: 'custom'}">カスタマイズの要望</router-link>
      </li>
    </ul>
  </div>
  <div class="navbar-nav ml-lg-auto" v-if="!setting.hideDeveloper">
    <small>このジェネレーターは日向ナナが作りました。</small>
  </div>
  <div class="navbar-nav flex-row justify-content-center" v-if="!setting.hideDeveloper">
    <a href="https://twitter.com/hinatanana7" class="btn-sm rounded-circle btn-twitter" target="_blank">
      <i class="fa-brands fa-x-twitter"></i>
    </a>
    <a href="https://www.youtube.com/@hinatanana7" class="btn-sm rounded-circle btn-youtube ml-2" target="_blank">
      <i class="fa-brands fa-youtube"></i>
    </a>
  </div>

</nav>
</template>

<script>
export default {
  computed: {
    setting() {
      return this.$store.state.setting
    },
  },
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->

<style lang="scss" scoped>
@import '@/assets/scss/custom.scss';

@media screen and (min-width: 992px) {
  .nav-item {
    position: relative;
  }

  .navbar-nav {
    .router-link-exact-active:after {
      content: "";
      position: absolute;
      left: 0;
      bottom: -9px;
      width: 100%;
      border-bottom: 4px solid $primary;
    }
  }
}

.btn-twitter {
  background: #000000;
  color: #ffffff;
}
.btn-youtube {
  background: #c23321;
  color: #ffffff;
}
</style>
