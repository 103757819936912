import Vue from 'vue'
import VueRouter from 'vue-router'
import custom from '@/store/config'
const setting = custom.setting;

Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    name: 'generator',
    component: () => import('@/views/Generator.vue'),
    meta: {
      disable: !setting.routes.generator,
      title: "バーチャル名刺背景ジェネレーター | オンライン名刺交換 オンライン名刺 バーチャル背景 バーチャル名刺"
    }
  },
  {
    path: '/howtouse',
    name: 'howtouse',
    component: () => import( '@/views/Howtouse.vue'),
    meta: {
      disable: !setting.routes.howtouse,
      title: "使い方 | バーチャル名刺背景ジェネレーター | オンライン名刺交換 オンライン名刺 バーチャル背景 バーチャル名刺"
    }
  },
  {
    path: '/feedback',
    name: 'feedback',
    component: () => import( '@/views/Feedback.vue'),
    meta: {
      disable: !setting.routes.howtouse,
      title: "ご意見箱 | バーチャル名刺背景ジェネレーター | オンライン名刺交換 オンライン名刺 バーチャル背景 バーチャル名刺"
    }
  },
  {
    path: '/custom',
    name: 'custom',
    component: () => import( '@/views/Custom.vue'),
    meta: {
      disable: !setting.routes.custom,
      title: "カスタマイズのご要望 | バーチャル名刺背景ジェネレーター | オンライン名刺交換 オンライン名刺 バーチャル背景 バーチャル名刺"
    }
  },
  {
    path: '*',
    name: 'home',
    redirect: setting.homePath
  },
]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes
})

router.beforeEach((to, from, next) => {
  if (to.matched.some((record) => record.meta.disable)) {
    if (from.name == null) {
      next({
        path: setting.homePath,
        query: {
          redirect: to.fullPath
        }
      })
    } else {
      next();
    }
  } else {
    next(); 
  }
});

router.afterEach((to, from) => { // eslint-disable-line
  if (to.meta && to.meta.title) {
    document.title = to.meta.title
  }
})

export default router
