// import snsTwitter from './images/snsTwitter'
import snsX from './images/snsX'
import snsFacebook from './images/snsFacebook'
import snsEight from './images/snsEight'
import snsLinkedIn from './images/snsLinkedIn'
import snsYoutube from './images/snsYoutube'
import snsInstagram from './images/snsInstagram'
import snsWeb from './images/snsWeb'

export default {
  disableBackgroundImageUpload: false,
  backgroundImage: [],
  disableCorpLogoUpload: false,
  disableCorpLogoDelete: false,
  corpLogo: [],
  color: {
    background: {
      swatch: [],
      advance: true,
    },
    text: {
      swatch: [],
      advance: true,
    },
  },
  fontfamily: [
    {
      text: 'ゴシック体',
      font: '"Noto Sans JP", sans-serif',
    },
    {
      text: '丸ゴシック',
      font: '"M PLUS Rounded 1c", sans-serif'
    },
    {
      text: '明朝体',
      font: '"Noto Serif JP", sans-serif'
    },
  ],
  sns: {
    twitter: {
      text: 'X(Twitter)',
      prepend: 'https://twitter.com/',
      icon: snsX,
      widthcorrection: 1
    },
    facebook: {
      text: 'Facebook',
      prepend: 'https://facebook.com/',
      icon: snsFacebook,
      widthcorrection: 1
    },
    // eight: {
    //   text: 'Eightプロフィールページ',
    //   prepend: 'https://8card.net/p/',
    //   icon: snsEight,
    //   widthcorrection: 1
    // },
    // eight_online: {
    //   text: 'Eightオンライン名刺',
    //   prepend: 'https://8card.net/virtual_cards/',
    //   icon: snsEight,
    //   widthcorrection: 1
    // },
    linkedIn: {
      text: 'LinkedIn',
      prepend: 'https://www.linkedin.com/in/',
      icon: snsLinkedIn,
      widthcorrection: 0.8
    },
    youtube: {
      text: 'Youtube',
      prepend: 'https://www.youtube.com/',
      icon: snsYoutube,
      widthcorrection: 1
    },
    instagram: {
      text: 'Instagram',
      prepend: 'https://www.instagram.com/',
      icon: snsInstagram,
      widthcorrection: 1
    },
    web: {
      text: 'Web URL',
      prepend: '',
      icon: snsWeb,
      widthcorrection: 0.9
    },
    eight_online: {
      text: 'Eightオンライン名刺',
      prepend: 'https://8card.net/virtual_cards/',
      icon: snsEight,
      widthcorrection: 1
    },
  },
  style: {
    backgroundColor: {
      width: {
        min: 0,
        max: 1920
      },
      height: {
        min: 0,
        max: 1080
      },
      posX: {
        min: 0,
        max: 1920
      },
      posY: {
        min: 0,
        max: 1080
      },
    },
    backgroundImage: {
      posX: {
        min: -1920,
        max: 1920
      },
      posY: {
        min: -1080,
        max: 1080
      },
      scale: {
        min: 0,
        max: 5
      }
    },
    corpLogo: {
      sizeWidth: {
        min: 0,
        max: 500
      },
      posX: {
        min: 0,
        max: 1920
      },
      posY: {
        min: 0,
        max: 1080
      },
    },
    department: {
      fontSize: {
        min: 20,
        max: 40
      },
      posX: {
        min: 0,
        max: 1920
      },
      posY: {
        min: 0,
        max: 1080
      },
    },
    title: {
      fontSize: {
        min: 20,
        max: 40
      },
      posX: {
        min: 0,
        max: 1920
      },
      posY: {
        min: 0,
        max: 1080
      },
    },
    nameMain: {
      fontSize: {
        min: 70,
        max: 120
      },
      posX: {
        min: 0,
        max: 1920
      },
      posY: {
        min: 0,
        max: 1080
      },
    },
    nameSub: {
      fontSize: {
        min: 30,
        max: 70
      },
      posX: {
        min: 0,
        max: 1920
      },
      posY: {
        min: 0,
        max: 1080
      },
    },
    mail: {
      qr: {
        posX: {
          min: 0,
          max: 1920
        },
        posY: {
          min: 0,
          max: 1080
        },
      },
    },
  },
}