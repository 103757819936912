import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import custom from '@/store/config'
const setting = custom.setting;

Vue.config.productionTip = false

// Bootstrap
import { BootstrapVue } from 'bootstrap-vue'
Vue.use(BootstrapVue)

// underscore
import underscore from 'vue-underscore';
Vue.use(underscore);

// GoogleTagManager
import VueGtm from '@gtm-support/vue2-gtm';
Vue.use(VueGtm, {
  id: 'GTM-NT8QN8F3',//your
  vueRouter: router
});
router.afterEach((to, from) => { // eslint-disable-line
  if (to.meta && to.meta.title) {
    window.dataLayer.push({
      'gaPath': setting.gaPath,
      'trackPageTitle': to.meta.title
    })
  }
})

// Google Adsense 用
import VueAdsense from 'vue-adsense'
Vue.component('adsense', VueAdsense);

new Vue({
  router,
  store,
  render: h => h(App)
}).$mount('#app')
