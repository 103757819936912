export default {
  backgroundColor: {
    color: '#FFFFFFD1',
    size: {
      width: 480,
      height: 1040,
    },
    pos: {
      x: 20,
      y: 20
    }
  },
  backgroundImage: {
    size: {
      width: 1920,
      height: 1080,
    },
    pos: {
      x: 0,
      y: 0
    },
    scale: 1
  },
  corpLogo: {
    size: {
      width: 400,
    },
    pos: {
      x: 50,
      y: 70
    }
  },
  department: {
    font: {
      family: '"Noto Sans JP"',
      size: 30,
      weight: 'normal'
    },
    color: '#000000',
    width: 1860,
    pos: {
      x: 60,
      y: 220
    }
  },
  title: {
    font: {
      family: '"Noto Sans JP"',
      size: 30,
      weight: 'normal'
    },
    color: '#000000',
    width: 1860,
    pos: {
      x: 60,
      y: 350
    }
  },
  nameMain: {
    font: {
      family: '"Noto Sans JP"',
      size: 100,
      weight: 'bold'
    },
    color: '#000000',
    width: 1860,
    pos: {
      x: 50,
      y: 400
    }
  },
  nameSub: {
    font: {
      family: '"Noto Sans JP"',
      size: 50,
      weight: 'normal'
    },
    color: '#000000',
    width: 1860,
    pos: {
      x: 60,
      y: 510
    }
  },
  mail: {
    qr: {
      size: {
        width: 0,
      },
      pos: {
        x: 50,
        y: 580
      },
    },
    text: {
      font: {
        family: '"Noto Sans JP"',
        size: 30,
        weight: 'normal'
      },
      width: 1860,
      color: '#000000',
    }
  },
  sns: [{
    qr: {
      size: {
        width: 220,
      },
      pos: {
        x: 1670,
        y: 50
      }
    },
    text: {
      font: {
        family: '"Noto Sans JP"',
        size: 26,
        weight: 'normal'
      },
      width: 200,
      color: '#000000',
    }
  },
  {
    qr: {
      size: {
        width: 220,
      },
      pos: {
        x: 1670,
        y: 430
      }
    },
    text: {
      font: {
        family: '"Noto Sans JP"',
        size: 26,
        weight: 'normal'
      },
      width: 200,
      color: '#000000',
    }
  },
  {
    qr: {
      size: {
        width: 220,
      },
      pos: {
        x: 1670,
        y: 810
      }
    },
    text: {
      font: {
        family: '"Noto Sans JP"',
        size: 26,
        weight: 'normal'
      },
      width: 200,
      color: '#000000',
    }
  }],
}