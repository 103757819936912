import Vue from 'vue'
import Vuex from 'vuex'
import custom from './config'

Vue.use(Vuex)

export default new Vuex.Store({
  state: {
    edit: custom.edit,
    style: custom.style,
    option: custom.option,
    setting: custom.setting,
  },
  mutations: {
    edit(state, edit) {
      state.edit = edit
    },
    style(state, style) {
      state.style = style
    },
  },
  actions: {
    edit(context) {
      context.commit('edit')
    },
    style(context) {
      context.commit('style')
    },
  },
  modules: {
  }
})
